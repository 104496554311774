import React from "react"
import { graphql } from "gatsby"

import EventListContent from "./event-list-content"

const Event = props => <EventListContent {...props} />

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $market: String) {
    sanityEventListing(tabs: { content: { market: { eq: $market } } }) {
      tabs {
        content {
          hero
          subtitle
          text: _rawText
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    allSanityEvent(
      filter: { tabs: { content: { type: { ne: "previous" }, market: { eq: $market } } } }
      sort: { fields: tabs___content___eventDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        tabs {
          content {
            title
            isEventLink
            eventLocation
            boothNumber
            subtitle
            excerpt
            type
            _rawFeaturedImage(resolveReferences: { maxDepth: 10 })
            category {
              title
              slug {
                current
              }
            }
            slug {
              current
            }
          }
        }
      }
    }
    allSanityEventCategory(filter: { market: { eq: $market } }) {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
  }
`

export default Event
